.home-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
}

.user-profile {
  flex: 8;
  text-align: left;
  margin: 20px; 
  min-width: 250px;
}

.container-information-user {
  text-align: left;
}

.container-information-user h2 {
  color: black;
}

.container-information-user h1 {
  color: #f60;
}

.container-information-user p {
}

.container-name-and-imagen {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: -70px;
  margin-left: 100px;
}

.container-name-and-imagen h1 {
  font-size: 3.5rem;
}

.profile-image-home {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 50%;
}

.points-tag {
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 10px;
  margin-left: 100px;
  font-size: 1.5rem;
  margin-top: -40px;
}



.triangle-icon {
  width: 100px;
  height: 100px;
  margin-left: 20px;
}

.section {
  flex: 1;
  min-width: 250px; 
  text-align: left;
}

.section span {
  font-size: 17px;
}

.section h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.items-section {
  flex: 3; 
}

.items-section h2 {
  margin-bottom: -6px;
}

.points-section {
  flex: 3;
  width: 100%;
}

.points-section h2 {
  margin-bottom: -6px;
}

.section h2 {
  text-align: left;
}

.items-list {
  list-style-type: none;
  padding: 0;
  max-height: 160px;
  min-height: 150px;
  overflow-y: auto;
}

.list-item {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  border-radius: 5px;
  text-align: left;
  justify-content: left;
  gap: 10px;
}

.item-icon {
  width: 50%;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

.list-item span {
  width: 50%;
  text-align: left;
}

.transaction-history-section-container-title {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.transaction-history-section-container-title h2 {
  margin-bottom: 25px !important;
}

.toggle-history-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

.toggle-history-btn:hover {
  color: #f60;
}


@media (max-width: 1473px) {
  .container-information-user h2 {
    font-size: 2.5rem;
  }

  .container-information-user h1 {
  }
  .container-information-user p{
  }

  .profile-image-home {
   
  }
  .points-tag {
  }
  .triangle-icon {
    width: 70px;
    height: 70px;
  }
}


@media (max-width: 768px) {

  .home-container {
    flex-direction: column; 
    align-items: center; 
  }

  .section {
    width: 100%;
    margin: 0;
  }

  .items-section, .points-section {
    order: 2;
    width: 100%;
  }

  .points-section {
    order: 3;
  }

  .container-name-and-imagen {
    flex-direction: column;
    align-items:center;
    gap: none;
    margin-top: -70px;
    margin-left: 0px;
  }
  .points-tag {
    gap: none;
    margin-left: 0px;
    font-size: 1rem;
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
  }
}