.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  user-select: none;
}

.custom-logo img {
  height: 100px;
}

.custom-navigation {
  display: flex;
  align-items: center;
}

.custom-navigation ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.custom-navigation ul li {
  font-size: 2rem;
  margin-left: 40px; 
  position: relative;
}

.custom-navigation ul li a {
  text-decoration: none;
  color: black;
}

.custom-navigation ul li.active a {
  color: black; 
  text-decoration: underline;
}

.custom-navigation ul li a:hover {
  text-decoration: underline;
}

.custom-profile {
  position: relative;
  cursor: pointer;
}

.custom-profile img {
  height: 60px;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 10px;
}

.custom-profile-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1000;
}

.custom-profile-menu button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;
}

.custom-profile-menu button:hover {
  background-color: #f0f0f0;
}

.custom-header-line {
  width: 95%;
  margin: 0 auto;
  border-bottom: 3px solid black;
}

.custom-hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1001;
  margin-right: 30px;
}

.custom-hamburger-menu .custom-bar {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: 0.4s;
}

.custom-hamburger-menu.open .custom-bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.custom-hamburger-menu.open .custom-bar:nth-child(2) {
  opacity: 0;
}

.custom-hamburger-menu.open .custom-bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.custom-tools-dropdown-content {
  display: none;
  position: absolute;
  font-size: 18px;
  background-color: white;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.custom-tools-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.custom-tools-dropdown-content a:hover {
  background-color: #f0f0f0;
}

.custom-tools-dropdown:hover .custom-tools-dropdown-content {
  display: block;
}

.custom-tools-dropdown a {
  text-decoration: none; /* Evitar subrayado */
}

.custom-tools-dropdown-content a {
  text-decoration: none!Important; /* Evitar subrayado */
}

@media (max-width: 948px) {
  .custom-navigation {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #f5f5f5;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1000;
  }

  .custom-navigation.open {
    display: flex;
  }

  .custom-navigation ul {
    flex-direction: column;
    width: 100%;
  }

  .custom-navigation ul li {
    margin: 20px 0;
    text-align: center;
  }

  .custom-hamburger-menu {
    display: flex;
  }

  .custom-profile img {
    height: 50px;
  }
}
