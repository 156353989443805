.container-button-admin-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.button-admin-item-update {
    width: 45%;
    padding: 10px;
    background-color: #ED6306;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button-admin-item-cancel {
    width: 40%;
    padding: 8px;
    background-color: #fff;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;
}

@media (max-width: 678px) {
    .container-button-admin-items {
        flex-direction: column;
    }

    .button-admin-item-update {
        width: 100%;
        margin: 10px 0 0 0;
    }

    .button-admin-item-cancel {
        width: 80%;
        margin: 15px 0 0 0;
    }
}