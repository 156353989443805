.admin-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.admin-container h1, h2, h3 {
    margin-bottom: 50px;
}

.admin-container h2 {
    text-align: center;
}

.title-item-admin {
  color: #ED6306;
  user-select: none;
}
.title-item-admin:hover {
    cursor: pointer;
    opacity: 0.7;
}

.admin-container-users {
  flex: 4 1;
}

.admin-container-items-left {
  flex: 1 1;
}

.container-addPoints-list-users {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* PARTE PARA AGREGAR USUARIOS */


.form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-container input[type="text"],
  .form-container input[type="email"],
  .form-container input[type="number"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;

    box-sizing: border-box;
  }
  
  .button-user-principal {
    width: 100%;
    padding: 10px;
    background-color: #ED6306;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button-user-principal:hover {
    opacity: 0.7;
}
  

.container-button-user-add {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.muted-button {
    margin-left: 30px;
}

@media (max-width: 768px) {
  .admin-container {
    display: flex;
    flex-direction: column;
}
}