.qr-generator-container-Master {
    display: flex;
    flex-direction: row;
    margin: 20px 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: space-around;
  }
  
  .qr-generator-container {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .qr-generator-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .qr-generator-input {
    width: 95%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .qr-generator-dropzone {
    border: 2px dashed #aaa;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .qr-generator-recommendation {
    margin-top: 10px;
    font-size: 14px;
    color: #FF6600;
  }
  
  .qr-generator-logo-preview {
    display: block;
    margin: 10px auto;
    max-width: 1500px;
    max-height: 100px;
  }
  
  .qr-generator-qrcode {
    text-align: center;
    margin-top: 20px;
  }
  
  .qr-generator-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .qr-generator-button:hover {
    background-color: #45a049;
  }

  .qr-generator-preview-container-none {
    display: none;
  }
  
  .qr-generator-preview {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  
  .qr-generator-preview-image {
    max-width: 350px;
    height: auto;
  }
  
  .qr-generator-download {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .qr-generator-download:hover {
    background-color: #0056b3;
  }
  
  .qrCode-view-preview {
    display: none;
  }
  
  @media (max-width: 768px) {
    .qr-generator-container-Master {
      flex-direction: column;
      align-items: center;
    }
  
    .qr-generator-preview-image {
      max-width: 100%;
    }
  }
  