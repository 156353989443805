/* Contenedor principal */
.modal-add-points-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-add-points-header {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .modal-add-points-user-info {
    margin-bottom: 15px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Estilos para los labels y inputs */

  .modal-add-points-container-label {
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    margin: 10px 0;
  }

  .modal-add-points-label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #333;
    user-select: none;
  }

  .modal-add-points-label:hover {
    cursor: pointer;
  }
  
  .modal-add-points-radio-input {
    margin-right: 8px;
    color: #ED6306;
  }

  .modal-add-points-select,
  .modal-add-points-number-input,
  .modal-add-points-text-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
    text-align: center;
  }
  
  @media (max-width: 680px) {
    .modal-add-points-container-label {
    flex-direction: column !important;
    }
  }
  

