.delete-button-user-admin {
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
    border: none;

}

.delete-button-user-admin:hover {
    cursor: pointer;
    background-color: #f44336;
    opacity: 0.7;
    color: white;
}