.login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.login-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 100%;
    background-color: #f60; 
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
    transform: rotate(0deg);
}

.logo {
    width: 50%;
    height: auto;
    transform: rotate(0deg);
}

.login-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: white; 
    position: relative;
    margin-left: 60%;
    text-align: center;
}

.login-container h2 {
    color: #f60; 
    width: 100%;
    text-align: left;
    font-size: xx-large;
    font-weight: bold;
}

.google-login-button {
    display: flex;
    align-items: center;
    background-color: white;
    color: #f60;
    border: 2px solid #f60;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
}

.google-login-button .icon {
    margin-right: 10px;
    font-size: 25px;
    color: #f60;
    opacity: 0.8;
}

.google-login-button:hover {
    background-color: #f60;
    color: white;
}

.google-login-button:hover .icon {
    color: white;
}


.rocket-image {
    position: absolute;
    bottom: 60px;
    right: 30px;
    width: 250px;
    height: auto;
    opacity: 0.9;
}

@media (max-width: 700px) {

    .login-container {
        justify-content: center;
        align-items: center;
    }

    .login-left {
        position: relative;
        width: 100%;
        clip-path: none;
        justify-content: center;
        align-items:flex-start;
    }

    .logo {
        margin-top: 30%;
    }
    .login-right {
        position:absolute;
        margin-left: 0;
        align-items: center;
        background-color: transparent;
        justify-content: center;
    }

    .login-container h2 {
        color: white;
        text-align: center;
    }

    .error-message {
        color: white;
    }
    .rocket-image {
        filter: invert(100%) brightness(1000%);
        right: 50%;
        width: 180px;
        position: absolute;
        top: 99%;
    }
}